* {
  margin: 0;
  padding: 0;
  border: 0;
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-display: swap;
  background: var(--light);
  height: auto;
}

ul,
li,
a {
  list-style: none;
  text-decoration: none;
  color: inherit;
}

img {
  width: inherit;
}

input,
textarea,
button {
  border: none;
  padding: 0;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--blue);
  border-radius: 2px;
}

button {
  cursor: pointer;
}
:root {
  --light: #f1f8f9;
  --blue: #50619f;
  --blueLight: #8b9cdb;
  --greenBold: #6ad9ab;
  --greenHover: rgb(48, 226, 152);
  --greenThin: #8aecc3;
  --black: #1b1b1b;
  --gray: #7e7e7e;
}

h1 {
  font-size: 2.8rem;
}

h2 {
  font-size: 2.6rem;
}

h3 {
  font-size: 2.4rem;
}

h4,
summary,
p {
  font-size: 1.5rem;
}

@media screen and (max-width: 1220px) {
  h1 {
    font-size: 2.6rem;
  }

  h2 {
    font-size: 2.4rem;
  }

  h3 {
    font-size: 2.2rem;
  }

  h4,
  summary,
  p {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1000px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2.3rem;
  }

  h3 {
    font-size: 2.1rem;
  }

  h4,
  summary,
  p {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 600px) {
  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  h4,
  summary,
  p {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 320px) {
  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.3rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  h4,
  summary,
  p {
    font-size: 1.1rem;
  }
}
a {
  cursor: pointer;
}

button {
  cursor: pointer;
}
